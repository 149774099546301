import { useState } from 'react';
import '../style/header.css'


function Header() {

    const [openBurger, setOpenBurger] = useState(true)
    const handleOpenMenu = () => setOpenBurger(!openBurger)
    const closeMenu = () => setOpenBurger(false)


  return (
    <div className="header">
        <div className="container">
            <div className="header-menu-wrapper">
                <div className="header-left">
                <div className="header-logo">
                    <img src="data:image/webp;base64,UklGRiQEAABXRUJQVlA4TBcEAAAvf4AJEL/luJEkRcpapuf57+Iy30wd+jD/DttIUqTqOc4/08fDKTe1tUfKlRAJccBIwAJKkEZLx0gIDtZu/cJONesAuBP4gx/4gy/4gS/4gC94gxd4gg7u4AxOm2MNGTaHxWG3M1gkmgASQAmNLpevn8FlZmE4/D2V0mEAkUgkgiBIkFLqsfm7jU6rzW61S7TCIdEOf4l2G51WFhZRCSJ4KZwG7TZ5Gux2oqBUdpmbSNSDxY8oLP6hYyFp0sBOOtEIjLDLnRQBG5EQ6WRaoruZVAcZJXmCuMo9RSSSJvqTkOhfRBdJWhA2sBQDKBbI7qkgCSrRkCiDwTJEw2TZInZbexpnBxeVvTx8K56SMWAXpKX+NUdNkcxdQQFzqxJUbpPOvFRQu7a9kCC9b7qq2tUcG7uztm17R2vb3vHM2ubYNn9gJW+qUd/zJBH9nwCyfOvNV/0rL8H7N9+iAn779ZdflLrX3y6Yd197wT6gSN54Pt83CmPP7ufy3o1ieOe9Z/P3rgj2vP/MID9AAXz49GDd+Nv11KDDuNv7pOX9d4WHnC3G3EdPiB+YzADOC5YfjalU+SaAZUvFD05gdACcs9RymUVdlRzQjlgeWhZpUHWTC6qZS8KICfh4ifgC7ty5BNcy/BL5JxaKUSXU2dZZJIzYkCpGnEusW75Y3OfrrufefQt4wWL5cgvWtEoWoWURC1LFQGMAny2SM+6YBFzo4RZZfp6HvslAoDwFdcXwDRrApwvlfb59IuOigLMXWn46OnUGVErGUHUutC12Pi4/13SDt9w5MnUGVEr6muesr7ZFO1bIH+7fNoVxsZu1wnbHqNQZiNqk9TGbnZdsX2l5r8/cvdJ6+4hUGIjapC8zoJJqWQGIJKvspzIuWZXjaJQZQI30gYGoTYAvM1AVbFttPY1x6Wp7NxztiLVVnV6luoSh2qSNGSXB1jXW0xjoXfbIGsutwxHYKCgBiNoaxaiQvsFAy7RlrXUG6F7+6FqxH452rHOmMkUMJBoGGoYmA8G0eZ31dA3QveKxdcLNwyHP1AhtxYAz1PLZ/4X1DAMw1wv3jURMAAIDCAREjMRQZYBMOPSl8Mbzr7nHS2Z48yEaHdQYUC2CY6CuSRUjkWz6ynwrZ7uCOV+ZN40SygxEhLZioJpSq+oZCJIDXwtnZ6RXOvOBkWpHDFQJDZY6kmDDN8J5Fjd9Y9xIlmooEgFSxYgJCN7g65RNGD5z8FvpfNnN3njQpq5KzoSYY8rVBV83oKFKDgDatZiBuNoibaq8y+D0d9KzRMF4msb++u+lTjAz6NfT+D/8/w/SW67SX33Gaf89XAA48t+P+f8TqBAP//1T3jhMRXnq53wdCvToX7/Y4ygV67GTv/8q9QFUwMdP/On++C04d+IYFSUA" alt="logo" />
                </div>
                <ul className='header-links'>
                    <li><a href="#">Main</a></li>
                    <li><a href="#">About</a></li>
                    <li><a href="#">Options</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>
            </div>
            <div className="header-rigth">
                    <a href="#">Get Free Quote</a>
            </div>
            <div className="burger-menu" onClick={handleOpenMenu}>
            <i class={openBurger ? 'fas fa-bars' : 'fas fa-times'}></i>
            </div>
            <div className={openBurger ? 'burger-menu-item' : 'burger-menu-item active'}>
                <ul>
                    <li><a href="#main" onClick={closeMenu}>Main</a></li>
                    <li><a href="#categories">Categories</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#partners">Partners</a></li>
                    <li><a href="#stuffs">Stuffs</a></li>
                </ul>
            </div>
                </div>
                
                
        </div>
    </div>
  );
}

export default Header;
