import '../style/main.css'


function Main() {
  return (
    <main className="main">
        <div className="container">
           <div className="main-content">
               <h1>We provide best IT solution</h1>
               <p>Naxly bring the power of data science and artificial intelligence to every business.</p>
               <a href="#">Our Services</a>
           </div>
           <div className="main-img">
               <img src="https://preview.colorlib.com/theme/itkol/assets/img/hero/xhero-img.png.pagespeed.ic.5EnILQZFRo.webp" alt="webp" />
           </div>
        </div>
    </main>
  );
}

export default Main;
