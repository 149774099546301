import '../style/services.css'


function Services() {
  return (
    <section className="services">
        <div className="container">
           <div className="services-content">
               <div className="services-title">
                   <span>Industries We Serve</span>
                   <h2>IT services customized for your industry</h2>
               </div>
               <div className="services-text">
                   <p>No matter the business, NanoSoft has you covered with industry compliant solutions, customized to your company’s specific needs.</p>
               </div>
           </div>
           <div className="services-blocks">
               <div className="services-item">
                   <div className="services-item-wrapper">
                    <div className="services-item-icon">
                        <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/stack1.svg" alt="img" />
                    </div>
                    <div className="services-item-text">
                        <h5>Industries & Manufacturing</h5>
                    </div>
                   </div>
               </div>

               <div className="services-item">
                   <div className="services-item-wrapper">
                    <div className="services-item-icon">
                        <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/stack1.svg" alt="img" />
                    </div>
                    <div className="services-item-text">
                        <h5>Industries & Manufacturing</h5>
                    </div>
                   </div>
               </div>

               <div className="services-item">
                   <div className="services-item-wrapper">
                    <div className="services-item-icon">
                        <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/stack1.svg" alt="img" />
                    </div>
                    <div className="services-item-text">
                        <h5>Industries & Manufacturing</h5>
                    </div>
                   </div>
               </div>

               <div className="services-item">
                   <div className="services-item-wrapper">
                    <div className="services-item-icon">
                        <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/stack1.svg" alt="img" />
                    </div>
                    <div className="services-item-text">
                        <h5>Industries & Manufacturing</h5>
                    </div>
                   </div>
               </div>
           </div>
           <div className="services-subtitle">
               <p>Need a Technology support? <a href="#">Get Started Now</a></p>
           </div>
        </div>
    </section>
  );
}

export default Services;
