import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Main from './components/Main'
import Categories from './components/Categories'
import About from './components/About'
import Services from './components/Services'
import Count from './components/Count'
import Stuff from './components/Stuff'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
        <Header />
          <Main />
          <Categories />
          <About />
          <Services />
          <Count />
          <Stuff />
          <Footer />
    </div>
  );
}

export default App;
