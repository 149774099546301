import '../style/categories.css'


function Categories() {
  return (
    <selection className="categories">
        <div className="container">
           <div className="categories-about">
               <div className="categories-wrapper">
                <span>Our Services</span>
                <h2>IT solutions for startap and enterprices</h2>
               </div>
           </div>
           <div className="categories-row">
               <div className="categories-block">
                   <div className="categories-block-img">
                       <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/services1.svg" alt="" />
                   </div>
                   <div className="categories-content">
                       <h5>Managed IT services</h5>
                       <p>Naxly bring the power of data science and artificial intelligence to every business.</p>
                   </div>
               </div>
               <div className="categories-block">
                   <div className="categories-block-img">
                       <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/services1.svg" alt="" />
                   </div>
                   <div className="categories-content">
                       <h5>Managed IT services</h5>
                       <p>Naxly bring the power of data science and artificial intelligence to every business.</p>
                   </div>
               </div>
               <div className="categories-block">
                   <div className="categories-block-img">
                       <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/services1.svg" alt="" />
                   </div>
                   <div className="categories-content">
                       <h5>Managed IT services</h5>
                       <p>Naxly bring the power of data science and artificial intelligence to every business.</p>
                   </div>
               </div>
           </div>
        </div>
    </selection>
  );
}

export default Categories;
