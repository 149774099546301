import '../style/about.css'


function About() {
  return (
    <section className="about">
        <div className="container">
           <div className="about-wrapper">
                <div className="about-img">
                    <img src="https://preview.colorlib.com/theme/itkol/assets/img/gallery/xabout1.jpg.pagespeed.ic.Xtb2FDlsGS.webp" alt="" />
                </div>
                <div className="about-content">
                    <div className="about-content_title">
                        <h2>Grow your business fast.</h2>
                    </div>
                    <div className="about-offers">
                        <div className="about-ofers-item">
                            <div className="item-img">
                                <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/notification1.svg" alt="" />
                            </div>
                            <div className="about-ofers-content">
                            <h3>Engage Teammates</h3>
                            <p>Naxly bring the power of data science and artificial intelligence to every business.</p>
                            </div>
                        </div>
                        
                        <div className="about-ofers-item">
                            <div className="item-img">
                                <img src="https://preview.colorlib.com/theme/itkol/assets/img/icon/notification1.svg" alt="" />
                            </div>
                            <div className="about-ofers-content">
                            <h3>Level Up Productivity</h3>
                            <p>Naxly bring the power of data science and artificial intelligence to every business.</p>
                            </div>
                        </div>
                        {/* <a href="#">Learn More</a> */}
                    </div>
                </div>
           </div>
        </div>
    </section>
  );
}

export default About;
