import '../style/stuff.css'


function Stuff() {
  return (
    <section className="stuff">
        <div className="container">
            <div className="stuff-title">
                <h2>Some of Our Great Stuffs</h2>
            </div>
            <div className="stuff-blocks">
                <div className="stuff-item">
                    <div className="stuff-item-wrapper">
                        <div className="stuff-img">
                            <img src="https://preview.colorlib.com/theme/itkol/assets/img/gallery/xstuffs1.jpg.pagespeed.ic.kQg4_LwMRr.webp" alt="a" />
                            <div className="img-detaled">
                                <div className="img-detaled-test">
                                    <span></span>
                                    <h4>inspire</h4>
                                    <p>design studio</p>
                                </div>
                                
                            </div>
                        </div>
                        <h3>The Security Risks of Changing Package Owners</h3>
                    </div>
                </div>

                <div className="stuff-item">
                    <div className="stuff-item-wrapper">
                        <div className="stuff-img">
                            <img src="https://preview.colorlib.com/theme/itkol/assets/img/gallery/xstuffs1.jpg.pagespeed.ic.kQg4_LwMRr.webp" alt="a" />
                            <div className="img-detaled">
                                <span></span>
                                <h4>inspire</h4>
                                <p>design studio</p>
                            </div>
                        </div>
                        <h3>The Security Risks of Changing Package Owners</h3>
                    </div>
                </div>

                <div className="stuff-item">
                    <div className="stuff-item-wrapper">
                        <div className="stuff-img">
                            <img src="https://preview.colorlib.com/theme/itkol/assets/img/gallery/xstuffs1.jpg.pagespeed.ic.kQg4_LwMRr.webp" alt="a" />
                            <div className="img-detaled">
                                <span></span>
                                <h4>inspire</h4>
                                <p>design studio</p>
            
                            </div>
                        </div>
                        <h3>The Security Risks of Changing Package Owners</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default Stuff;
