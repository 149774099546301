import '../style/footer.css'


function Footer() {
  return (
    <footer className="footer">
        <div className="footer-padding">
           <div className="container">
               <div className="footer-blocks">
                   <div className="footer-item">
                       <div className="footer-wraper">
                           <div className="footer-logo">
                               <a href="#"><img src="https://preview.colorlib.com/theme/itkol/assets/img/logo/xlogo2_footer.png.pagespeed.ic.00mFalZtBh.webp" alt="footer" /></a>
                           </div>
                           <div className="footer-title">
                               <p>Duis aute irure dolor inasfa reprehenderit in voluptate velit esse cillum</p>
                           </div>
                           <div className="footer-subtitle">
                               <ul>
                                   <li className="number">10(87)7383940</li>
                                   <li className="email">contact@it-kol.com</li>
                               </ul>
                           </div>
                       </div>
                   </div>

                   <div className="footer-item">
                       <div className="footer-wraper">
                           <div className="footer-title">
                               <h4>Navigation</h4>
                               <ul>
                                   <li><a href="#">Home</a></li>
                                   <li><a href="#">About</a></li>
                                   <li><a href="#">Services</a></li>
                                   <li><a href="#">Blog</a></li>
                                   <li><a href="#">About</a></li>
                               </ul>
                           </div>
                       </div>
                   </div>

                   <div className="footer-item">
                       <div className="footer-wraper">
                           <div className="footer-title">
                               <h4>Services</h4>
                               <ul>
                                   <li><a href="#">Drone maping</a></li>
                                   <li><a href="#">Real State</a></li>
                                   <li><a href="#">Comercial</a></li>
                                   <li><a href="#">Drone maping</a></li>
                               </ul>
                           </div>
                       </div>
                   </div>

                   <div className="footer-item">
                       <div className="footer-wraper">
                           <div className="footer-title">
                               <h4>Subscribe newsletter</h4>
                               <p>Subscribe our newsletter to get updates about our services and offers.</p>
                           </div>
                           <div className="foter-form">
                               <input type="text" />
                           </div>
                           <div className="footer-social">
                               <a href=""></a><a href=""></a><a href=""></a><a href=""></a>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    </footer>
  );
}

export default Footer;
