import '../style/count.css'


function Count() {
  return (
    <main className="count">
        <div className="container">
           <div className="count-title">
               <div className="count-title-section">
                   <div className="count-title-content">
                       <h2>ITKol - Partner for Your Business</h2>
                       <p>We understand the complexities of modern markets and translate them into real business solutions for automotive, financial, insurance, pharma & life sciences, and real estate with more to come.</p>
                       <a href="#">Request a Quote</a>
                   </div>
               </div>
           </div>
           <div className="count-counter">
               <div className="count-item">
                   <div className="count-item-wrapper">
                       <div className="counter-number">
                           <span>2500+</span>
                       </div>
                       <div className="counter-text">
                           <p>We understand the complexities of modern markets</p>
                       </div>
                   </div>
               </div>
               <div className="count-item">
                   <div className="count-item-wrapper">
                       <div className="counter-number">
                           <span>350+</span>
                       </div>
                       <div className="counter-text">
                           <p>We understand the complexities of modern markets</p>
                       </div>
                   </div>
               </div>
               <div className="count-item">
                   <div className="count-item-wrapper">
                       <div className="counter-number">
                           <span>25+</span>
                       </div>
                       <div className="counter-text">
                           <p>We understand the complexities of modern markets</p>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    </main>
  );
}

export default Count;
